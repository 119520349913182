import { ILauncherSpace } from './interfaces';

export const ENVIRONMENT: 'dev' | 'stage' | 'prod' | 'bits' | 'k5' | 'nwx' | 'metro' = 'prod';
export const DEFAULT_MULTISPACE_URL = 'https://multispace-prod.hspx.de';
export const DEFAULT_PLATFORM_URL = 'https://app.hellospaces.de/';
export const DEFAULT_PLATFORM_SHARE_URL = 'https://app.hellospaces.de/app/spacesummary/';
export const PRESET_SPACEIDS: string[] | undefined = undefined;
export const PRESET_SPACE_FALLBACK: ILauncherSpace | undefined = undefined;
export const SENTRY_DSN = 'https://f86f16d7055845e5be2f36fb93365f87@sentry.hspx.de/2';
export const IOS_APPSTORE_URL = 'https://apps.apple.com/de/app/hellospaces/id1615726233'
export const GOOGLE_PLAYSTORE_URL = 'https://play.google.com/store/apps/details?id=de.hellospaces.app'
export const MIN_BUILD_VERSION_IOS = 8;
export const MIN_BUILD_VERSION_ANDROID = 15;
export const APP_STORE_URL_IOS = 'https://apps.apple.com/us/app/hellospaces/id1615726233';
export const APP_STORE_URL_ANDROID = 'https://play.google.com/store/apps/details?id=de.hellospaces.app';
export const DEEPLINK_SHORT = 'hspx://';

export const APP_LANGUAGE: 'de' | 'en' | undefined = undefined;

export const QuotaLimits = {
	attendees: 50,
	admins: 1,
	moderators: 1
};
